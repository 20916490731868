import { getAnonymousUserId } from '$extensionSrc/metrics/idGetSet'

const UTM_PARAM_NAMES: (keyof AttributionData)[] = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
]

export type AttributionData = {
  referrer?: string
  pathname?: string
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  utm_term?: string
}

/**
 * Gather attribution data from document properties and url params
 *
 * Includes referrer if and only if it is not a blockpartyapp.com domain
 */
export function getAttributionData(): AttributionData {
  const data: AttributionData = {}
  const referrer = document.referrer
  let referrerHostname: string | undefined
  try {
    const referrerUrl = new URL(referrer)
    referrerHostname = referrerUrl.hostname
  } catch {
    // do nothing
  }
  if (referrerHostname && !referrerHostname.endsWith('.blockpartyapp.com')) {
    data.referrer = referrer
  }
  let url: URL | undefined
  try {
    url = new URL(window.location.href)
  } catch {
    // do nothing
  }
  if (url) {
    for (const paramName of UTM_PARAM_NAMES) {
      const value = url.searchParams.get(paramName)
      if (value) {
        data[paramName] = value
      }
    }
    data.pathname = url.pathname
  }
  return data
}

export async function updateLinks(): Promise<void> {
  // Read attribution data from cookie, and use to update /install/ links
  const attributionData: AttributionData = getAttributionData()
  const anonymousUserId = await getAnonymousUserId()

  const selectors = ['a[href*=\\.privacypartyapp\\.com]']
  if (process.env.NODE_ENV === 'development') {
    selectors.push('a[href*="127.0.0.1"]')
  }

  const selector = selectors.join(', ')

  const privacyPartyLinks =
    document.querySelectorAll<HTMLAnchorElement>(selector)
  for (const link of privacyPartyLinks) {
    if (link.href) {
      let updatedUrl: URL | undefined
      try {
        updatedUrl = new URL(link.href)
        // eslint-disable-next-line no-empty
      } catch {}

      if (updatedUrl) {
        // Point to staging if on staging
        const isStaging =
          window.location.href.includes('staging.') ||
          window.location.href.includes('webflow.io')

        if (isStaging) {
          // Note: this means that staging will now point to
          //       app.staging.blockpartyapp.com (instead of)
          //       privacypartyapp.com
          updatedUrl.hostname = 'app.staging.blockpartyapp.com'
        } else {
          // Note: we do this to make it easier to migrate to
          //       app.blockpartyapp.com; at a later date we will
          //       manually replace the urls on Webflow
          updatedUrl.hostname = 'app.blockpartyapp.com'
        }

        if (anonymousUserId) {
          updatedUrl.searchParams.set('anonId', anonymousUserId)
        }

        for (const [key, value] of Object.entries(attributionData)) {
          updatedUrl.searchParams.set(key, value)
        }

        link.href = updatedUrl.href
      }
    }
  }
}
