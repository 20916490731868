import BuildConfig from '../buildConfig'

export const privacyPolicyUrl = 'https://www.blockpartyapp.com/privacy-policy/'
export const tosUrl = 'https://www.blockpartyapp.com/tos/'
export const unavailableSettingFaqUrl =
  'https://www.blockpartyapp.com/faqs/why-is-a-setting-not-available-for-me/'
export const languageSupportFaqUrl =
  'https://www.blockpartyapp.com/faqs/language-support'
export const xRateLimitFaqUrl =
  'https://www.blockpartyapp.com/faqs/limitations-on-x/'

export const chromeWebStoreUrl =
  'https://chromewebstore.google.com/detail/hefhjoddehdhdgfjhpnffhopoijdfnak'

export const firefoxWebStoreUrl =
  'https://addons.mozilla.org/en-US/firefox/addon/privacy-party/'

export const extensionDashboardUrl = '/dashboard/index.html'
export const extensionUserDataConsentPageUrl =
  '/user-data-consent-page/index.html'
export const extensionDebugHomeUrl = '/debug/index.html'
export const extensionSidebarUrl = '/sidebar/index.html'
export const extensionSidebar2Url = '/sidebar2/index.html'
export const extensionFullScreenModalUrl = '/full-screen-modal/index.html'

export const dashboardNotificationPanelOpenQuery = '?panel=OPEN'
export const dashboardDeeplinkQueryParam = 'link'

export function getExtensionUrl(path, queryParams) {
  if (typeof chrome === 'undefined' || !chrome?.runtime) {
    return null
  }
  try {
    const url = new URL(chrome.runtime.getURL(path))
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
    }
    return url.href
  } catch {
    throw new Error(`Failed to construct extension URL with path ${path}`)
  }
}

// API paths
export const apiOrigin = BuildConfig.EXTENSION_API_ORIGIN
export function getApiUrl(path, queryParams) {
  try {
    const url = new URL(path, apiOrigin)
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
    }
    return url.href
  } catch {
    throw new Error(
      `Failed to construct URL with path ${path} and apiOrigin ${apiOrigin}`,
    )
  }
}
export const confirm2faPath = '/api/confirm-2fa/'
export const confirmEmailPromptPath = '/confirm-email-prompt/'
export const enroll2faAppConfirmPath = '/enroll-2fa-app-confirm/'
export const enroll2faAppPath = '/enroll-2fa-app/'
export const enroll2faSmsConfirmPath = '/enroll-2fa-sms-confirm/'
export const enroll2faSmsPath = '/enroll-2fa-sms/'
export const extensionRedirectPath = '/extension-redirect/'
export const feedbackBugReportFormPath = '/feedback-form/'
export const googleOauthPath = '/google/oauth-request/'
export const graphqlPath = '/api/graphql/'
export const graphqlUnauthPath = '/api/graphql-unauth/'
export const homePath = '/'
export const installPath = '/install/'
export const loginPath = '/login/'
export const login2faPath = '/login-2fa/'
export const notificationPreferencesPath = '/notification-settings/'
export const nuxPath = '/nux/'
export const paymentPath = '/payment/'
export const qrcodePath = '/qrcode/'
export const selectPlanPath = '/select-plan/'
export const send2faSmsPath = '/api/send-sms-2fa-confirmation-code/'
export const sendResetPassword = '/send-reset-password/'
export const settingsPath = '/settings/'
export const signupPath = '/signup/'
export const signupEmailFormPath = '/signup-email-form/'
export const signupLoginEnterprisePath = '/signup-login-enterprise/'
export const tokenRefreshPath = '/refresh/'
export const uninstallPath = '/uninstall/'

export const suggestFeatureTallyFormUrl = 'https://tally.so/r/mOz4Dg'
export const labPartyFacebookTallyFormUrl = 'https://tally.so/r/nrVgB2'
export const labPartyInstagramTallyFormUrl = 'https://tally.so/r/m6kWzB'
export const labPartyTwitterTallyFormUrl = 'https://tally.so/r/waGxzW'
export const internationalWaitlistTallyFormUrl = 'https://tally.so/r/nrdeEl'

// Cookie domain utils
export const sharedCookieDomain = BuildConfig.SHARED_COOKIE_DOMAIN

// Marketing site URLs
export const marketingSiteOrigin = BuildConfig.MARKETING_SITE_ORIGIN
export function getMarketingSiteUrl(path) {
  try {
    const url = new URL(path, marketingSiteOrigin)
    return url.href
  } catch {
    throw new Error(
      `Failed to construct URL with path ${path} and origin ${marketingSiteOrigin}`,
    )
  }
}

export const pricingUrl = getMarketingSiteUrl('/pricing')

// These are the URLs that force a log in screen even if the user is already logged in
export const forceLoginUrlFacebook = null
export const forceLoginUrlGoogle =
  'https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Faccounts.google.com%2F&flowName=GlifWebSignIn'
export const forceLogoutUrlGoogle = 'https://accounts.google.com/Logout'
export const forceLoginUrlInstagram = null
export const forceLoginUrlLinkedin = null
export const forceLoginUrlReddit = null
export const forceLoginUrlStrava = null
export const forceLoginUrlTwitter = 'https://x.com/i/flow/login'
export const forceLoginUrlVenmo = 'https://account.venmo.com/sign-in'
export const forceLoginUrlYoutube =
  'https://accounts.google.com/AddSession?continue=https%3A%2F%2Fwww.youtube.com%2Fsignin%3Faction_handle_signin%3Dtrue%26app%3Ddesktop%26hl%3Den%26next%3D%252F&hl=en&passive=false&service=youtube&uilel=0'

export const loginUrlFacebook = 'https://www.facebook.com/login'
export const loginUrlGoogle = forceLoginUrlGoogle
export const loginUrlInstagram = 'https://www.instagram.com/accounts/login/'
export const loginUrlLinkedIn = 'https://www.linkedin.com/login'
export const loginUrlReddit = 'https://www.reddit.com/login/'
export const loginUrlStrava = 'https://www.strava.com/login'
export const loginUrlTwitter = forceLoginUrlTwitter
export const loginUrlVenmo = forceLoginUrlVenmo
export const loginUrlYoutube = forceLoginUrlYoutube

// Mail URLS
export const appleMailURL = 'message://'
export const gmailURL = 'https://mail.google.com/'

export const makeConfirmEmailURL = (email) => {
  const queryParams = new URLSearchParams()
  queryParams.set('email', email)

  return `${confirmEmailPromptPath}?${queryParams.toString()}`
}

export const platformUrlFacebook = 'https://www.facebook.com'
export const platformUrlGoogle = 'https://www.google.com'
export const platformUrlInstagram = 'https://www.instagram.com'
export const platformUrlLinkedin = 'https://www.linkedin.com'
export const platformUrlReddit = 'https://www.reddit.com'
export const platformUrlStrava = 'https://www.strava.com'
export const platformUrlTwitter = 'https://x.com'
export const platformUrlVenmo = 'https://venmo.com/'
export const platformUrlYoutube = 'https://www.youtube.com/'

// Content script blocklist

const contentScriptBlocklist = [
  new URL('https://accounts.google.com'),
  new URL('https://analytics.google.com'),
  new URL('https://calendar.google.com'),
  new URL('https://docs.google.com'),
  new URL('https://drive.google.com'),
  new URL('https://mail.google.com'),
  new URL('https://www.google.com/search'),
  new URL('https://www.google.com/maps'),
  new URL('https://business.strava.com'),
  new URL('https://labs.strava.com'),
  new URL('https://partners.strava.com'),
  new URL('https://press.strava.com'),
  new URL('https://stories.strava.com'),
  new URL('https://support.strava.com'),
  new URL('https://help.venmo.com/'),
  new URL('https://www.facebook.com/privacy/consent'),
]

export function isCurrentUrlOnBlocklist() {
  try {
    const url = new URL(window.location.href)
    return contentScriptBlocklist.some((blocklistUrl) => {
      if (blocklistUrl.host !== url.host) {
        return false
      }

      if (
        blocklistUrl.pathname &&
        url.pathname.startsWith(blocklistUrl.pathname)
      ) {
        return true
      }

      return false
    })
  } catch {
    return false
  }
}
